exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actualites-category-tsx": () => import("./../../../src/pages/actualites/[...category].tsx" /* webpackChunkName: "component---src-pages-actualites-category-tsx" */),
  "component---src-pages-actualites-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/actualites/{markdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-actualites-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-carte-tsx": () => import("./../../../src/pages/carte.tsx" /* webpackChunkName: "component---src-pages-carte-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infolettre-tsx": () => import("./../../../src/pages/infolettre.tsx" /* webpackChunkName: "component---src-pages-infolettre-tsx" */),
  "component---src-pages-join-consommateurs-tsx": () => import("./../../../src/pages/join/consommateurs.tsx" /* webpackChunkName: "component---src-pages-join-consommateurs-tsx" */),
  "component---src-pages-join-magasins-tsx": () => import("./../../../src/pages/join/magasins.tsx" /* webpackChunkName: "component---src-pages-join-magasins-tsx" */),
  "component---src-pages-join-producteurs-tsx": () => import("./../../../src/pages/join/producteurs.tsx" /* webpackChunkName: "component---src-pages-join-producteurs-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-producteurs-index-tsx": () => import("./../../../src/pages/producteurs/index.tsx" /* webpackChunkName: "component---src-pages-producteurs-index-tsx" */),
  "component---src-pages-projet-equipe-tsx": () => import("./../../../src/pages/projet/equipe.tsx" /* webpackChunkName: "component---src-pages-projet-equipe-tsx" */),
  "component---src-pages-projet-index-tsx": () => import("./../../../src/pages/projet/index.tsx" /* webpackChunkName: "component---src-pages-projet-index-tsx" */),
  "component---src-pages-projet-partenaires-tsx": () => import("./../../../src/pages/projet/partenaires.tsx" /* webpackChunkName: "component---src-pages-projet-partenaires-tsx" */),
  "component---src-pages-status-mdx": () => import("./../../../src/pages/status.mdx" /* webpackChunkName: "component---src-pages-status-mdx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */)
}

